import Button, { ButtonWithPreview } from "@components/Button";
import DealCouponModal from "@components/_shared/DealCouponModal";
import Image from "@components/_shared/NextImage";
import TextDirection from "@components/_shared/TextDirection";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import {
  getDealCollectionClickEvent,
  getDealCouponModalClickEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import { getLocaleInUrl } from "@helpers/site";
import { buildHomePageDealClickAttrs } from "@helpers/webEngage/clickEventBuilders";
import { DealCollectionType } from "@shopcashTypes/dealCollectionType";
import { DealCouponModalCtaType } from "@shopcashTypes/dealCouponModalCtaType";
import { GenzoDealCouponModalAction } from "@shopcashTypes/genzoDealCouponModalAction";
import { VariantButton } from "@shopcashTypes/variantButton";
import {
  WebEngageClickEvent,
  WebEngageDealType,
} from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useState } from "react";
import styles from "./DealCardCouponAgnostic.module.scss";

interface DealCardCouponAgnosticProps {
  deal?: StoreDeal;
  index: number;
  loading?: boolean;
}

const DealCardCouponAgnostic: React.FC<DealCardCouponAgnosticProps> = ({
  deal,
  index,
  loading,
}) => {
  const translate = useTranslations();
  const router = useRouter();
  const { user } = useAuth();
  const { isRtl } = useSiteConfig();
  const [showModal, setShowModal] = useState(false);
  const { trackEvent } = useGenzoTracking();
  const { trackWebEngageClickEvent } = useWebEngageTracking();
  const { locale, defaultLocale } = useSiteConfig();

  const handleVisitDealClick = (deal: StoreDeal) => {
    const event = getDealCollectionClickEvent(
      deal,
      index,
      "coupon-agnostic",
      "visit_store"
    );
    trackEvent(event);
    trackWebEngageClickEvent(
      WebEngageClickEvent.homePageDealsClick,
      buildHomePageDealClickAttrs({
        dealType: WebEngageDealType.CouponAgnostic,
        ...deal,
      })
    );

    if (user) {
      const localeInUrl = getLocaleInUrl(defaultLocale, locale);
      const dealHandoffUrl = `${localeInUrl}/handoff/deal/${deal.id}`;

      window.open(dealHandoffUrl, "_blank");
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push({ pathname: `/store/${deal.store.slug}` });
    }
  };

  const handleVisitStoreClick = (slug: string): void => {
    const event = getDealCollectionClickEvent(
      deal,
      index,
      "coupon-agnostic",
      "visit_store"
    );
    trackEvent(event);
    trackWebEngageClickEvent(
      WebEngageClickEvent.homePageDealsClick,
      buildHomePageDealClickAttrs({
        dealType: WebEngageDealType.CouponAgnostic,
        ...deal,
      })
    );
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({ pathname: `store/${slug}` });
  };

  const handleShowCodeClick = (isLoggedIn: boolean): void => {
    const event = getDealCollectionClickEvent(
      deal,
      index,
      "coupon-agnostic",
      "show_code"
    );
    trackEvent(event);
    setShowModal(true);

    if (isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigator?.clipboard?.writeText(deal.couponCode);
    }
  };

  const handleDealCouponModalClose = (): void => {
    setShowModal(false);
  };

  const handleEventTrack = (ctaType: DealCouponModalCtaType) => {
    switch (ctaType) {
      case DealCouponModalCtaType.ShopNow:
        trackEvent(
          getDealCouponModalClickEvent(
            "home",
            "deal_collections",
            deal.id,
            !!deal.store.featuredCashback,
            GenzoDealCouponModalAction.ShopNow,
            "coupon_agnostic"
          )
        );
        break;
      case DealCouponModalCtaType.SignInForCode:
        trackEvent(
          getDealCouponModalClickEvent(
            "home",
            "deal_collections",
            deal.id,
            !!deal.store.featuredCashback,
            GenzoDealCouponModalAction.SignInForCode,
            "coupon_agnostic"
          )
        );
        break;
      case DealCouponModalCtaType.CopyCode:
        trackEvent(
          getDealCouponModalClickEvent(
            "home",
            "deal_collections",
            deal.id,
            !!deal.store.featuredCashback,
            GenzoDealCouponModalAction.CopyCode,
            "coupon_agnostic"
          )
        );
        break;
      case DealCouponModalCtaType.StoreLogo:
        trackEvent(
          getDealCouponModalClickEvent(
            "home",
            "deal_collections",
            deal.id,
            !!deal.store.featuredCashback,
            GenzoDealCouponModalAction.StoreLogo,
            "coupon_agnostic"
          )
        );
        trackWebEngageClickEvent(
          WebEngageClickEvent.homePageDealsClick,
          buildHomePageDealClickAttrs({
            dealType: WebEngageDealType.CouponAgnostic,
            ...deal,
          })
        );
        break;
    }
  };

  return (
    <div className={clsx(isRtl && styles.rtl)} data-testid="deal-card">
      {showModal && deal?.couponCode && (
        <DealCouponModal
          show={showModal}
          deal={deal}
          onCloseClick={handleDealCouponModalClose}
          onEventTrack={handleEventTrack}
          collectionType={DealCollectionType.CouponAgnostic}
        ></DealCouponModal>
      )}

      {loading ? (
        <div
          className={clsx(styles.cardBody, styles.empty)}
          data-testid="deal-card-loading"
        >
          <div className={styles.dealTop}>
            <div className={styles.dealName}></div>
            <div className={styles.button}></div>
          </div>

          <div className={styles.storeDetails}>
            <div className={styles.storeLogo}></div>
            <div className={styles.storeName}></div>
          </div>
        </div>
      ) : (
        <div className={styles.cardBody}>
          <div className={styles.dealTop}>
            <div className={styles.dealName}>{deal?.title}</div>

            <div>
              {deal.couponCode ? (
                <ButtonWithPreview
                  onClick={() => handleShowCodeClick(!!user)}
                  previewText={deal.couponCode}
                >
                  {translate("show_code")}
                </ButtonWithPreview>
              ) : (
                <Button
                  variant={VariantButton.Primary}
                  onClick={() => handleVisitDealClick(deal)}
                  fullWidth
                >
                  {translate("visit_store")}
                </Button>
              )}
            </div>
          </div>

          <div className={styles.storeDetails}>
            <div
              className={styles.storeLogo}
              onClick={() =>
                loading ? null : handleVisitStoreClick(deal?.store?.slug)
              }
            >
              {!loading && (
                <Image
                  src={deal?.store?.logoUrl}
                  layout="fill"
                  objectFit="contain"
                  alt={deal?.store?.name}
                ></Image>
              )}
            </div>

            <div className={styles.storeName}>
              <TextDirection text={deal?.store?.name} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealCardCouponAgnostic;
