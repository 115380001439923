import { API_HOST, parseResponseBody } from "./apiHelpers";

export const getHeroCollection = (
  siteCode: string,
  appType: string,
  language = "en"
): Promise<BannerCollection> => {
  const params = [
    `appType=${appType}`,
    `siteCode=${siteCode}`,
    `language=${language}`,
  ].join("&");

  const url = `${API_HOST}/banner-collection/hero?${params}`;
  return fetch(url)
    .then((r: Response) => r.json())
    .then((body: BannerCollectionResponse) =>
      parseResponseBody<BannerCollectionResponse, BannerCollection>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getBannerCollection = (
  siteCode: string,
  appType: string,
  language = "en",
  collectionNumber: number
): Promise<BannerCollection> => {
  const params = [
    `appType=${appType}`,
    `siteCode=${siteCode}`,
    `language=${language}`,
  ].join("&");

  const url = `${API_HOST}/banner-collection/mid-page${
    collectionNumber ? `-${collectionNumber}` : ``
  }?${params}`;
  return fetch(url)
    .then((r) => {
      if (!r.ok) {
        throw new Error("API response was not ok");
      }
      return r.json();
    })
    .then((body: BannerCollectionResponse) =>
      parseResponseBody<BannerCollectionResponse, BannerCollection>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getFlashDealCollection = (
  siteCode: string,
  appType: string,
  language = "en"
): Promise<DealCollection> => {
  const url = `${API_HOST}/deal-collection/flash?appType=${appType}&siteCode=${siteCode}&language=${language}`;
  return fetch(url)
    .then((r: Response) => r.json())
    .then((body: DealCollectionResponse) =>
      parseResponseBody<DealCollectionResponse, DealCollection>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getDealsWithCouponCollection = (
  siteCode: string,
  appType: string,
  language = "en"
): Promise<DealCollection> => {
  const url = `${API_HOST}/deal-collection/with-coupon?appType=${appType}&siteCode=${siteCode}&language=${language}`;
  return fetch(url)
    .then((r: Response) => r.json())
    .then((body: DealCollectionResponse) =>
      parseResponseBody<DealCollectionResponse, DealCollection>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getDealsWithOutCouponCollection = (
  siteCode: string,
  appType: string,
  language = "en"
): Promise<DealCollection> => {
  const url = `${API_HOST}/deal-collection/without-coupon?appType=${appType}&siteCode=${siteCode}&language=${language}`;
  return fetch(url)
    .then((r: Response) => r.json())
    .then((body: DealCollectionResponse) =>
      parseResponseBody<DealCollectionResponse, DealCollection>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getDealsAgnosticCollection = (
  siteCode: string,
  appType: string,
  language = "en"
): Promise<DealCollection> => {
  const url = `${API_HOST}/deal-collection/coupon-agnostic?appType=${appType}&siteCode=${siteCode}&language=${language}`;
  return fetch(url)
    .then((r: Response) => r.json())
    .then((body: DealCollectionResponse) =>
      parseResponseBody<DealCollectionResponse, DealCollection>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};
