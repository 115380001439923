import clsx from "clsx";
import styles from "./CategoriesTab.module.scss";

const FALLBACK_ICON =
  "https://assets.wego.com/image/upload/v1620811697/shopcash/stg/categories/icons/2e4827bb-48de-431a-be83-aa02a01caf9a.svg";

interface CategoriesTabProps {
  category?: Category;
  onCategoriesTabClick?: (category: Category) => void;
  isSelected?: boolean;
}

const CategoriesTab: React.FC<CategoriesTabProps> = ({
  category,
  onCategoriesTabClick,
  isSelected,
}) => {
  const isLoading = !category;

  return (
    <div
      className={clsx(
        styles.container,
        isSelected && styles.selected,
        isLoading && styles.isLoading
      )}
      onClick={() => {
        onCategoriesTabClick(category);
      }}
    >
      <img src={category?.iconUrl || FALLBACK_ICON} />

      <div className={clsx(styles.textContainer)}>{category?.name}</div>
    </div>
  );
};

export default CategoriesTab;
