import { getStoreViews } from "@api/account";
import { getCategories } from "@api/categories";
import { getHeroCollection } from "@api/collections";
import {
  getDiscoverStores,
  getStoreCollections,
  getStores,
  getTrendingStores,
} from "@api/stores";
import AccountSummary from "@components/AccountSummary";
import AllDeals from "@components/AllDeals";
import BannerCollection from "@components/BannerCollection";
import Button from "@components/Button";
import Categories from "@components/Categories";
import DealCollection from "@components/DealCollection";
import DiscoverStores from "@components/DiscoverStores";
import FeaturedStores from "@components/FeaturedStores";
import Footer from "@components/Footer";
import Header from "@components/Header";
import HeroCarousel from "@components/HeroCarousel";
import PhoneVerification from "@components/PhoneVerification";
import RecentlyViewedStoreList from "@components/RecentlyViewedStoreList";
import SeoHead from "@components/SeoHead";
import TrendingStores from "@components/TrendingStores";
import UserEducationVideoButton from "@components/UserEducationVideoButton";
import UserEducationVideoModal from "@components/UserEducationVideoModal";
import ZohoChatWidget from "@components/ZohoChatWidget";
import Modal from "@components/_shared/Modal";
import { useAuth } from "@context/authentication";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useGtmTracking } from "@context/tracking/gtm";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import withSiteConfig, { getSiteConfig } from "@context/withSiteConfig";
import withTranslations, { getTranslations } from "@context/withTranslations";
import { getCookie, setCookie } from "@helpers/cookie";
import {
  getAllDealItemClickEvent,
  getAllDealLoadMoreClickEvent,
  getDealCouponModalClickEvent,
  getDiscoverStoresClickEvent,
  getPhoneVerificationIntroductionNextClickEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import { getHomePageView } from "@helpers/genzo/genzoPageViewBuilders";
import { getHomePageExtraData } from "@helpers/gtm/gtmPageViewBuilders";
import { getSiteNameSchema } from "@helpers/seo";
import useAllDeals from "@helpers/useAllDeals";
import useBannerCollections from "@helpers/useBannerCollections";
import useDealCollections from "@helpers/useDealCollections";
import { buildHomePageDealClickAttrs } from "@helpers/webEngage/clickEventBuilders";
import { buildHomePageAttrs } from "@helpers/webEngage/pageViewBuilders";
import CrossIcon from "@icons/cross.svg";
import { DealCouponModalCtaType } from "@shopcashTypes/dealCouponModalCtaType";
import { GenzoDealCouponModalAction } from "@shopcashTypes/genzoDealCouponModalAction";
import { VariantButton } from "@shopcashTypes/variantButton";
import { VariantDealCard } from "@shopcashTypes/variantDealCard";
import {
  WebEngageClickEvent,
  WebEngageDealType,
  WebEngagePageView,
} from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import type { GetServerSideProps, NextPage } from "next";
import Head from "next/head";
import { useEffect, useState } from "react";
import useSWR from "swr";
import styles from "./index.module.scss";
interface HomeProps {
  siteConfig: SiteConfig;
  dealBannerCollection: BannerCollection;
}

const HomePage: NextPage<HomeProps> = ({ siteConfig }) => {
  const { trackPageView, trackEvent } = useGenzoTracking();
  const {
    trackWebEngagePageView,
    trackWebEngageClickEvent,
  } = useWebEngageTracking();

  const { trackGtmPageView } = useGtmTracking();

  const { accessToken, user } = useAuth();
  const translate = useTranslations();
  const { siteCode, locale, appType, isRtl, isMobile, env } = siteConfig;
  const [isUserEducationModalOpen, setIsUserEducationModalOpen] = useState(
    false
  );
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);

  const { data: heroImageCollection } = useSWR(
    ["heroCollection", siteCode, appType, locale],
    (_: string, siteCode: string, appType: string, language: string) =>
      getHeroCollection(siteCode, appType, language)
  );

  const {
    withCouponsDealCollection,
    withoutCouponsDealCollection,
    couponAgnosticDealCollection,
  } = useDealCollections();

  const { bannerCollection1, bannerCollection2 } = useBannerCollections();

  const { data: trendingStores } = useSWR(
    ["trendingStores", siteCode, locale, 12],
    (_: string, siteCode: string, locale: string, perPage: number) =>
      getTrendingStores(siteCode, locale, perPage)
  );

  const { data: discoverStores } = useSWR(
    ["discoverStores", siteCode, locale, 12],
    (_: string, siteCode: string, locale: string, perPage: number) =>
      getDiscoverStores(siteCode, locale, perPage)
  );

  const { data: storeViewsResponse } = useSWR(
    accessToken ? ["storeViews", accessToken, siteCode, 30, 1, 8] : null,
    (
      _: string,
      accessToken: string,
      siteCode: string,
      maxAge: number,
      page: number,
      perPage: number
    ) => getStoreViews(accessToken, siteCode, maxAge, page, perPage)
  );

  const { storeViews } = storeViewsResponse || {};

  const { data: categoriesResponse } = useSWR(
    [locale, "position"],
    getCategories
  );

  const { categories } = categoriesResponse || {};

  const [selectedCategorySlug, setSelectedCategorySlug] = useState<string>();
  const { data: categoryStores } = useSWR(
    selectedCategorySlug
      ? [selectedCategorySlug, siteCode, appType, locale, 1, 12, "score"]
      : null,
    getStores
  );

  const { data: storeCollections } = useSWR(
    ["storeCollections", siteCode, appType, locale],
    (_: string, siteCode: string, appType: string, locale: string) =>
      getStoreCollections(siteCode, appType, locale)
  );

  const {
    allDeals,
    currentPage,
    loadMoreDeals,
    hasMoreDeals,
    loadingDeals,
  } = useAllDeals();

  const handleAllDealsLoadMoreClick = (): void => {
    const event = getAllDealLoadMoreClickEvent(currentPage);
    trackEvent(event);
    loadMoreDeals();
  };

  const handleOnSelectedCategoryChange = (categorySlug: string): void => {
    setSelectedCategorySlug(categorySlug);
  };

  const handleUserVideoButtonClick = (): void => {
    setIsUserEducationModalOpen(
      (prevIsUserEducationModalOpen) => !prevIsUserEducationModalOpen
    );
  };

  useEffect(() => {
    trackPageView(getHomePageView());
    trackWebEngagePageView(WebEngagePageView.homePage, buildHomePageAttrs());
  }, []);

  useEffect(() => {
    if (!user) return;

    const hasRequestedPhoneVerifcation = getCookie(
      `phoneVerificationRequested:${user.id}`
    );

    if (!user?.profile.phoneNumber && !hasRequestedPhoneVerifcation) {
      setShowPhoneVerification(true);
    }
  }, [user]);

  useEffect(() => {
    trackGtmPageView("homepage", getHomePageExtraData());
  }, [locale]);

  const handleDiscoverStoreClick = (
    store: StoreSummary,
    index: number
  ): void => {
    const event = getDiscoverStoresClickEvent(store, index);
    trackEvent(event);
  };

  const handleAllDealItemClick = (deal: StoreDeal, index: number): void => {
    const event = getAllDealItemClickEvent(deal, index);
    trackEvent(event);
    trackWebEngageClickEvent(
      WebEngageClickEvent.homePageDealsClick,
      buildHomePageDealClickAttrs({
        dealType: WebEngageDealType.AllDeals,
        ...deal,
      })
    );
  };

  const handleAllDealModalItemClick = (
    deal: StoreDeal,
    ctaType: DealCouponModalCtaType
  ) => {
    let modalAction: GenzoDealCouponModalAction;

    switch (ctaType) {
      case DealCouponModalCtaType.ShopNow:
        modalAction = GenzoDealCouponModalAction.ShopNow;
        break;
      case DealCouponModalCtaType.SignInForCode:
        modalAction = GenzoDealCouponModalAction.SignInForCode;
        break;
      case DealCouponModalCtaType.CopyCode:
        modalAction = GenzoDealCouponModalAction.CopyCode;
        break;
      case DealCouponModalCtaType.StoreLogo:
        modalAction = GenzoDealCouponModalAction.StoreLogo;

        trackWebEngageClickEvent(
          WebEngageClickEvent.homePageDealsClick,
          buildHomePageDealClickAttrs({
            dealType: WebEngageDealType.AllDeals,
            ...deal,
          })
        );
        break;
    }

    trackEvent(
      getDealCouponModalClickEvent(
        "home",
        "all_deals",
        deal.id,
        !!deal.store.featuredCashback,
        modalAction
      )
    );
  };

  const handlePhoneVerificationContinue = () => {
    setShowPhoneVerification(false);
    setCookie(`phoneVerificationRequested:${user.id}`, true);
  };

  return (
    <>
      <SeoHead page="homepage" />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, initial-scale=1.0, user-scalable=0"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(getSiteNameSchema(env, siteCode)),
          }}
        />
      </Head>
      <ZohoChatWidget />

      <Header />
      <main
        role="main"
        className={clsx(
          styles.container,
          isRtl && styles.rtl,
          isMobile && styles.isMobile
        )}
      >
        {isMobile && (
          <div className={clsx(styles.wrap, styles.accountSummaryContainer)}>
            <AccountSummary />
          </div>
        )}

        <div className={styles.heroContainer}>
          <HeroCarousel {...heroImageCollection} />
        </div>

        <div className={styles.wrap}>
          <Categories
            categories={categories}
            stores={categoryStores?.stores}
            isRtl={isRtl}
            onSelectedCategoryChange={handleOnSelectedCategoryChange}
          />

          <BannerCollection {...bannerCollection1} isRtl={isRtl} />
        </div>

        {(withCouponsDealCollection === undefined ||
          withCouponsDealCollection?.deals.length > 0) && (
          <div
            className={styles.greyBackground}
            data-testid="deal-collection-with-coupons"
          >
            <div className={styles.wrap}>
              <DealCollection
                title={withCouponsDealCollection?.title}
                deals={withCouponsDealCollection?.deals}
                variant={VariantDealCard.withCoupon}
              ></DealCollection>
            </div>
          </div>
        )}

        <div className={styles.wrap}>
          {storeViews?.length > 0 && (
            <RecentlyViewedStoreList
              storeViews={storeViews}
              isRtl={isRtl}
              title={translate("recently_viewed")}
            />
          )}

          <TrendingStores {...trendingStores} isRtl={isRtl} />

          {bannerCollection2 !== null && (
            <BannerCollection {...bannerCollection2} isRtl={isRtl} />
          )}
        </div>

        {(withoutCouponsDealCollection === undefined ||
          withoutCouponsDealCollection?.deals.length > 0) && (
          <div
            className={styles.greyBackground}
            data-testid="deal-collection-without-coupons"
          >
            <div className={styles.wrap}>
              <DealCollection
                title={withoutCouponsDealCollection?.title}
                deals={withoutCouponsDealCollection?.deals}
                variant={VariantDealCard.withoutCoupon}
              ></DealCollection>
            </div>
          </div>
        )}

        <div className={styles.wrap}>
          {storeCollections ? (
            <>
              {storeCollections.map((sc) => (
                <FeaturedStores {...sc} key={sc.id} />
              ))}
            </>
          ) : (
            <>
              <FeaturedStores />
            </>
          )}
        </div>

        {(couponAgnosticDealCollection === undefined ||
          couponAgnosticDealCollection?.deals.length > 0) && (
          <div
            className={styles.greyBackground}
            data-testid="deal-collection-coupon-agnostic"
          >
            <div className={styles.wrap}>
              <DealCollection
                title={couponAgnosticDealCollection?.title}
                deals={couponAgnosticDealCollection?.deals}
                variant={VariantDealCard.couponAgnostic}
              ></DealCollection>
            </div>
          </div>
        )}

        <div className={clsx(styles.wrap, styles.twoColumns, styles.last)}>
          <div>
            <AllDeals
              deals={allDeals}
              onDealClick={handleAllDealItemClick}
              onDealCouponModalItemClick={handleAllDealModalItemClick}
            />
            <div className={styles.loadDealsContainer}>
              {hasMoreDeals && !loadingDeals && (
                <Button
                  variant={VariantButton.OutlinePrimary}
                  onClick={handleAllDealsLoadMoreClick}
                  large
                >
                  {translate("load_more")}
                </Button>
              )}

              {loadingDeals && (
                <img src="https://assets.wego.com/image/upload/c_scale,w_60/v1626670110/web/shopcash/animations/deals-loader.gif" />
              )}
            </div>
          </div>
          <DiscoverStores
            {...discoverStores}
            isRtl={isRtl}
            title={translate("discover_stores")}
            onStoreClick={handleDiscoverStoreClick}
          />
        </div>
        <UserEducationVideoButton
          onClick={handleUserVideoButtonClick}
          isRtl={isRtl}
          locale={locale}
        />

        {isUserEducationModalOpen && (
          <UserEducationVideoModal onCloseClick={handleUserVideoButtonClick} />
        )}

        <Modal
          className={styles.phoneVerificationModal}
          show={showPhoneVerification}
        >
          <CrossIcon
            onClick={handlePhoneVerificationContinue}
            className={styles.closeIcon}
          ></CrossIcon>
          <PhoneVerification
            accessToken={accessToken}
            onContinue={handlePhoneVerificationContinue}
            onIntroNextClick={() => {
              trackEvent(
                getPhoneVerificationIntroductionNextClickEvent("click")
              );
            }}
          />
        </Modal>
      </main>
      <Footer />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const env = process.env.APP_ENV;
  const siteConfig = getSiteConfig(context, env);
  const translations = await getTranslations(siteConfig);

  return {
    props: {
      siteConfig,
      translations,
    },
  };
};

export default withTranslations(withSiteConfig(HomePage));
