import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import Head from "next/head";
import { useEffect, useState } from "react";

const ZohoChatWidget: React.FC = () => {
  const { locale, isRtl } = useSiteConfig();
  const { user, isAuthenticating } = useAuth();
  const [currentLocale, setCurrentLocale] = useState(locale);
  const [currentUser, setCurrentUser] = useState(user);

  useEffect(() => {
    if (locale !== currentLocale) {
      resetZohoChat(isRtl, user);
      setCurrentLocale(locale);
    }
  }, [locale]);

  useEffect(() => {
    if (user !== currentUser) {
      resetZohoChat(isRtl, user);
      setCurrentUser(user);
    }
  }, [user]);

  const resetZohoChat = (isRtl: boolean, user?: User) => {
    window.$zoho.salesiq.ready = () => {
      window.$zoho.salesiq.chat.defaultdepartment("Shopcash");
      window.$zoho.salesiq.pastchat.visible("show");
      window.$zoho.salesiq.floatbutton.coin.hidetooltip();
      window.$zoho.salesiq.tracking.on();

      window.$zoho.salesiq.language(locale);
      window.$zoho.salesiq.floatbutton.position(
        `${isRtl ? "bottomleft" : "bottomright"}`
      );

      if (user) {
        window.$zoho.salesiq.visitor.info({ userHash: `${user.userHash}` });
      }

      if (user?.profile?.email) {
        window.$zoho.salesiq.visitor.email(user.profile.email);
      }

      if (user?.profile?.name) {
        window.$zoho.salesiq.visitor.name(user.profile.name);
      }
    };

    if (window.$ZSIQWidget && window.$zoho.salesiq.reset) {
      window.$zoho.salesiq.reset();
    }
  };

  return (
    <Head>
      {!isAuthenticating && locale && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            var $zoho = $zoho || {};
            $zoho.salesiq = $zoho.salesiq || {
              widgetcode:
                "siqf1403d459d2589d475700fbfe73bb6858f21da57247e439df1e224217df6ba04",
              values: {},
              ready: () => {
                $zoho.salesiq.chat.defaultdepartment("Shopcash");
                $zoho.salesiq.pastchat.visible('show');
                $zoho.salesiq.floatbutton.coin.hidetooltip();
                $zoho.salesiq.tracking.on();

                $zoho.salesiq.language("${locale}");
                $zoho.salesiq.floatbutton.position("${
                  isRtl ? "bottomleft" : "bottomright"
                }");

                ${
                  user
                    ? `$zoho.salesiq.visitor.info({"userHash": "${user.userHash}"});`
                    : ""
                }

                ${
                  user?.profile.email
                    ? `$zoho.salesiq.visitor.email("${user.profile.email}");`
                    : ""
                }

                ${
                  user?.profile.name
                    ? `$zoho.salesiq.visitor.name("${user.profile.name}");`
                    : ""
                }
              },
            };
            

            var d = document;

            if (!d.getElementById("zsiqscript") && !d.getElementById("zsiqwidget")) {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.id = "zsiqscript";
              s.defer = true;
              s.src = "https://salesiq.zoho.com/widget";
  
              
              var t = d.getElementsByTagName("script")[0];
              t.parentNode.insertBefore(s, t);
  
              var w = document.createElement("div");
              w.id = "zsiqwidget";
              
              var b = d.getElementsByTagName("body")[0];
              b.appendChild(w);
            }
            `,
          }}
        />
      )}
    </Head>
  );
};

export default ZohoChatWidget;
