import Carousel from "@components/Carousel";
import {
  DealCardCouponAgnostic,
  DealCardWithCoupon,
  DealCardWithoutCoupon,
} from "@components/DealCollection/DealCard";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import { getDealCollectionSeeAllClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import { VariantDealCard } from "@shopcashTypes/variantDealCard";
import clsx from "clsx";
import Link from "next/link";
import React from "react";
import styles from "./DealCollection.module.scss";

interface DealCollectionProps {
  title: string;
  deals: Array<StoreDeal>;
  variant: VariantDealCard;
}

const DealCollection: React.FC<DealCollectionProps> = ({
  title,
  deals,
  variant,
}) => {
  const { isRtl } = useSiteConfig();
  const translate = useTranslations();
  const { trackEvent } = useGenzoTracking();

  const skeletonScaffoldArr = new Array(5).fill(undefined);

  const getCardByVariant = (
    variant: VariantDealCard,
    deal,
    key,
    index,
    loading = false
  ) => {
    switch (variant) {
      case VariantDealCard.withCoupon:
        return (
          <DealCardWithCoupon
            deal={deal}
            key={key}
            index={index}
            loading={loading}
          ></DealCardWithCoupon>
        );
      case VariantDealCard.withoutCoupon:
        return (
          <DealCardWithoutCoupon
            deal={deal}
            key={key}
            index={index}
            loading={loading}
          ></DealCardWithoutCoupon>
        );
      case VariantDealCard.couponAgnostic:
        return (
          <DealCardCouponAgnostic
            deal={deal}
            key={key}
            index={index}
            loading={loading}
          ></DealCardCouponAgnostic>
        );
    }
  };

  const handleSeeAllClick = (): void => {
    const event = getDealCollectionSeeAllClickEvent(variant);
    trackEvent(event);
  };

  return (
    <>
      <section
        className={clsx(styles.dealCollectionContainer, isRtl && styles.rtl)}
      >
        <div className={styles.dealCollectionHeader}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.seeAll}>
            <Link href="/deals">
              <a onClick={handleSeeAllClick}>{translate("see_all")}</a>
            </Link>
          </div>
        </div>
        <Carousel isRtl={isRtl} rendered={deals?.length > 0}>
          <div
            className={clsx(styles.gridContainer)}
            data-testid={`deal-collection-${variant}`}
          >
            {deals == undefined &&
              skeletonScaffoldArr.map((val, i) =>
                getCardByVariant(variant, val, i, i, true)
              )}
            {deals?.length > 0 &&
              deals.map((deal, index) =>
                getCardByVariant(variant, deal, deal.id, index)
              )}
          </div>
        </Carousel>
      </section>
    </>
  );
};

export default DealCollection;
