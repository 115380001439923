import { useSiteConfig } from "@context/siteConfig";
import ChevronIcon from "@icons/chevron.svg";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Carousel.module.scss";

const CARDS_PER_WIDTH = [3, 3, 4];

interface CarouselProps {
  children: React.ReactNode;
  isRtl: boolean;
  rendered?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({ children, isRtl, rendered }) => {
  const carouselContainer = useRef<HTMLDivElement>(null);
  const [isLeftButtonHidden, setIsLeftButtonHidden] = useState<boolean>(true);
  const [isRightButtonHidden, setIsRightButtonHidden] = useState<boolean>(true);
  const { isMobile } = useSiteConfig();

  const handleOnLeftScrollClick = (): void => {
    moveContent("prev");
  };
  const handleOnRightScrollClick = (): void => {
    moveContent("next");
  };

  const moveContent = (action: string): void => {
    const isPrev = action === "prev";
    const scroller = carouselContainer.current;
    const dist = (isRtl ? -1 : 1) * getScrollDistance(scroller.offsetWidth);
    const newPosition = scroller.scrollLeft + (isPrev ? -dist : dist);

    setIsLeftButtonHidden(isRtl ? -5 <= newPosition : newPosition <= 5);
    setIsRightButtonHidden(
      isRtl
        ? -(scroller.scrollWidth - scroller.offsetWidth) >= newPosition
        : newPosition >= scroller.scrollWidth - scroller.offsetWidth
    );

    void smoothScrollHorizontal(scroller, newPosition, 200);
  };

  useEffect(() => {
    if (rendered === undefined) {
      setIsLeftButtonHidden(true);
      setIsRightButtonHidden(false);
    } else if (rendered === true) {
      const scroller = carouselContainer.current;
      const hasExtraItem = !!(scroller.scrollWidth - scroller.offsetWidth);

      setIsLeftButtonHidden(true);
      setIsRightButtonHidden(!hasExtraItem);
    }
  }, [isRtl, rendered]);

  return (
    <div
      className={clsx(
        styles.carouselContainer,
        isRtl && styles.rtl,
        isMobile && styles.isMobile
      )}
    >
      <div className={styles.carousel} ref={carouselContainer}>
        {children}
      </div>

      <div
        className={clsx(styles.scrollLeft, isLeftButtonHidden && styles.hidden)}
        onClick={handleOnLeftScrollClick}
      >
        <ChevronIcon />
      </div>
      <div
        className={clsx(
          styles.scrollRight,
          isRightButtonHidden && styles.hidden
        )}
        onClick={handleOnRightScrollClick}
      >
        <ChevronIcon />
      </div>
    </div>
  );
};

const getScrollDistance = (width) => {
  const viewPortWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  let cardsPerWidthIndex = 0;

  if (viewPortWidth > 1023 && viewPortWidth < 1280) {
    cardsPerWidthIndex = 1;
  } else if (viewPortWidth > 1279) {
    cardsPerWidthIndex = 2;
  }

  const numberOfViewableCards = CARDS_PER_WIDTH[cardsPerWidthIndex];
  return (width / numberOfViewableCards) * (numberOfViewableCards - 1);
};

// -=-=-=-=-
// copy paste from makalu, will optimize later
const smoothScrollHorizontal = (
  element: HTMLDivElement,
  target: number,
  duration: number
) => {
  target = Math.round(target);
  duration = Math.round(duration);
  if (duration < 0) {
    return Promise.reject("bad duration");
  }
  if (duration === 0) {
    element.scrollLeft = target;
    return Promise.resolve();
  }

  const startTime = Date.now();
  const endTime = startTime + duration;

  const startLeft = element.scrollLeft;
  const distance = target - startLeft;

  // based on http://en.wikipedia.org/wiki/Smoothstep
  const smoothStep = function (start, end, point) {
    if (point <= start) {
      return 0;
    }
    if (point >= end) {
      return 1;
    }
    const x = (point - start) / (end - start); // interpolation
    return x * x * (3 - 2 * x);
  };

  return new Promise<void>(function (resolve) {
    // This is to keep track of where the element's scrollLeft is
    // supposed to be, based on what we're doing
    let previousLeft = element.scrollLeft;

    // This is like a think function from a game loop
    const scrollFrame = function () {
      // try {
      //   window.requestAnimationFrame();
      // } catch (e) {}

      if (element.scrollLeft != previousLeft) {
        // reject("interrupted");
        return;
      }

      // set the scrollLeft for this frame
      const now = Date.now();
      const point = smoothStep(startTime, endTime, now);
      const frameLeft = Math.round(startLeft + distance * point);
      element.scrollLeft = frameLeft;

      // check if we're done!
      if (now >= endTime) {
        return resolve();
      }

      // If we were supposed to scroll but didn't, then we
      // probably hit the limit, so consider it done; not
      // interrupted.
      if (
        element.scrollLeft === previousLeft &&
        element.scrollLeft !== frameLeft
      ) {
        return resolve();
      }
      previousLeft = element.scrollLeft;

      // schedule next frame for execution
      setTimeout(scrollFrame, 0);
    };

    // boostrap the animation process
    setTimeout(scrollFrame, 0);
  });
};

export default Carousel;
