import Carousel from "@components/Carousel";
import StoreCard from "@components/StoreCard";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import {
  getCategoryClickEvent,
  getCategorySeeAllClickEvent,
  getCategoryStoreEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import clsx from "clsx";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import styles from "./Categories.module.scss";
import CategoriesTab from "./CategoriesTab";

interface CategoriesProps {
  categories: Array<Category>;
  stores: Array<StoreSummary>;
  isRtl: boolean;
  onSelectedCategoryChange: (categorySlug: string) => void;
}

const Categories: React.FC<CategoriesProps> = ({
  categories,
  stores,
  isRtl,
  onSelectedCategoryChange,
}) => {
  const translate = useTranslations();
  const { trackEvent } = useGenzoTracking();
  const { isMobile } = useSiteConfig();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const scaffolds = [...Array(10)];
  const categoryStoresContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selectedCategory && !!categories?.length) {
      setSelectedCategory(categories[0]);
    }
  }, [selectedCategory, categories]);

  useEffect(() => {
    onSelectedCategoryChange(selectedCategory?.slug);
  }, [selectedCategory]);

  const handleSeeAllClick = (): void => {
    const event = getCategorySeeAllClickEvent();
    trackEvent(event);
  };

  const handleCategoriesTabClick = (
    category: Category,
    index: number
  ): void => {
    setSelectedCategory(category);
    const event = getCategoryClickEvent(category, index);
    trackEvent(event);
    if (categoryStoresContainerRef.current.scrollLeft != 0) {
      categoryStoresContainerRef.current.scrollLeft = 0;
    }
  };

  const handleCategoryStoreClick = (
    store: StoreSummary,
    index: number,
    categoryId: string
  ): void => {
    const event = getCategoryStoreEvent(store, index, categoryId);
    trackEvent(event);
  };

  return (
    <section
      className={clsx(
        styles.section,
        styles.categoriesSection,
        isRtl && styles.rtl,
        isMobile && styles.isMobile
      )}
    >
      <div className={styles.title}>
        <span>{translate("popular_categories")}</span>
        <Link href="/category/all-stores">
          <a className={styles.seeAll} onClick={handleSeeAllClick}>
            {translate("see_all")}
          </a>
        </Link>
      </div>

      <Carousel isRtl={isRtl}>
        <div
          className={clsx(styles.categoriesTabs, isMobile && styles.isMobile)}
        >
          {/* categories data has not returned yet */}
          {categories === undefined && (
            <>
              {scaffolds.map((_, index) => (
                <CategoriesTab key={index} />
              ))}
            </>
          )}

          {/* categories data returns a value */}
          {categories?.length > 0 &&
            categories.map((category, index) => {
              return (
                <CategoriesTab
                  key={category.id}
                  category={category}
                  onCategoriesTabClick={() =>
                    handleCategoriesTabClick(category, index)
                  }
                  isSelected={selectedCategory?.id === category.id}
                />
              );
            })}
        </div>
      </Carousel>

      <div
        className={styles.categoryStoresContainer}
        ref={categoryStoresContainerRef}
      >
        <div className={styles.categoryStores}>
          {/* stores data has not returned yet */}
          {stores === undefined && (
            <>
              {scaffolds.map((_, index) => (
                <StoreCard key={index} />
              ))}
            </>
          )}

          {/* stores data returns a value */}
          {stores?.length > 0 &&
            stores?.map((categoryStore, i) => (
              <div
                key={categoryStore.id}
                onClick={() =>
                  handleCategoryStoreClick(
                    categoryStore,
                    i,
                    selectedCategory.id
                  )
                }
              >
                <StoreCard store={categoryStore} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Categories;
