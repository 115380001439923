import StoreLogo from "@components/StoreLogo";
import { useGenzoTracking } from "@context/tracking/genzo";
import { getRecentlyViewedClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import clsx from "clsx";
import { FC } from "react";
import styles from "./RecentlyViewedStoreList.module.scss";

type RecentlyViewedStoreListProps = {
  storeViews?: Array<StoreView>;
  isRtl: boolean;
  title: string;
};

const RecentlyViewedStoreList: FC<RecentlyViewedStoreListProps> = ({
  storeViews,
  isRtl,
  title,
}) => {
  return (
    <>
      {
        <section className={clsx(styles.section, isRtl && styles.rtl)}>
          <div className={styles.title}>{title}</div>

          <div className={styles.contentContainer}>
            <div className={styles.content}>
              {storeViews === undefined && <LogosSkeleton />}
              {storeViews?.length > 0 && <Logos storeViews={storeViews} />}
            </div>
          </div>
        </section>
      }
    </>
  );
};

const Logos: FC<{ storeViews: StoreView[] }> = ({ storeViews }) => {
  const { trackEvent } = useGenzoTracking();

  const handleStoreViewClick = (storeView: StoreView, index: number): void => {
    const event = getRecentlyViewedClickEvent(storeView, index);
    trackEvent(event);
  };

  return (
    <>
      {storeViews?.map((storeView, index) => (
        <div
          className={styles.logoContainer}
          key={storeView?.id}
          onClick={() => {
            handleStoreViewClick(storeView, index);
          }}
        >
          <StoreLogo store={storeView?.store} />
        </div>
      ))}
    </>
  );
};

const LogosSkeleton = () => (
  <>
    {Array.from(Array(8).keys()).map((i) => (
      <div className={styles.logoContainer} key={i}>
        <StoreLogo />
      </div>
    ))}
  </>
);

export default RecentlyViewedStoreList;
