import StoreCard from "@components/StoreCard";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import { getTrendingStoreEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import { buildTrendingStoreClickAttrs } from "@helpers/webEngage/clickEventBuilders";
import { WebEngageClickEvent } from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import Link from "next/link";
import styles from "./TrendingStores.module.scss";

interface TrendingStoresProps {
  stores?: Array<StoreSummary>;
  isRtl: boolean;
}

const TrendingStores: React.FC<TrendingStoresProps> = ({ stores, isRtl }) => {
  const { trackEvent } = useGenzoTracking();
  const { trackWebEngageClickEvent } = useWebEngageTracking();
  const translate = useTranslations();
  const { isMobile } = useSiteConfig();

  const skeletonsArray = [...new Array(12)];

  const handleTrendingStoreClick = (
    store: StoreSummary,
    index: number
  ): void => {
    const event = getTrendingStoreEvent(store, index);
    trackEvent(event);
    trackWebEngageClickEvent(
      WebEngageClickEvent.trendingStoreClick,
      buildTrendingStoreClickAttrs(store)
    );
  };

  return (
    <section
      className={clsx(
        styles.section,
        styles.trendingStoresSection,
        isRtl && styles.rtl
      )}
    >
      <div className={clsx(styles.title, isMobile && styles.isMobile)}>
        <span>{translate("trending_stores_title")}</span>
        <Link href="/popular-stores">
          <a className={styles.seeAll}>{translate("see_all")}</a>
        </Link>
      </div>

      <div
        className={clsx(
          styles.trendingStoresContainer,
          isMobile && styles.isMobile
        )}
      >
        <div
          className={clsx(styles.trendingStores, isMobile && styles.isMobile)}
          data-testid="trending-stores-container"
        >
          {stores === undefined &&
            skeletonsArray.map((_, i) => <StoreCard key={i} />)}

          {stores?.length &&
            stores.map((store, i) => {
              return (
                <div
                  key={store.id}
                  onClick={() => handleTrendingStoreClick(store, i)}
                >
                  <StoreCard store={store} />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default TrendingStores;
