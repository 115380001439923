import Button from "@components/Button";
import Image from "@components/_shared/NextImage";
import TextDirection from "@components/_shared/TextDirection";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import { getDateDiff, GetDateDiffType, UTCtoLocalTime } from "@helpers/date";
import { getDealCollectionClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import { getLocaleInUrl } from "@helpers/site";
import { buildHomePageDealClickAttrs } from "@helpers/webEngage/clickEventBuilders";
import { VariantButton } from "@shopcashTypes/variantButton";
import {
  WebEngageClickEvent,
  WebEngageDealType,
} from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import { useRouter } from "next/router";
import styles from "./DealCardWithoutCoupon.module.scss";

const SHOPCASH_LOGO =
  "https://assets.wego.com/image/authenticated/s--s8qSzrBA--/v1622111396/web/shopcash/shopcash-logo-grey.svg";
interface DealCardWithoutCouponProps {
  deal?: StoreDeal;
  index: number;
  loading?: boolean;
}

const DealCardWithoutCoupon: React.FC<DealCardWithoutCouponProps> = ({
  deal,
  index,
  loading,
}) => {
  const translate = useTranslations();
  const { isRtl } = useSiteConfig();
  const { trackEvent } = useGenzoTracking();
  const { trackWebEngageClickEvent } = useWebEngageTracking();
  const { locale, defaultLocale } = useSiteConfig();
  const { user } = useAuth();
  const router = useRouter();

  const handleVisitDealClick = (deal: StoreDeal) => {
    const event = getDealCollectionClickEvent(
      deal,
      index,
      "without-coupon",
      "visit_store"
    );
    trackEvent(event);
    trackWebEngageClickEvent(
      WebEngageClickEvent.homePageDealsClick,
      buildHomePageDealClickAttrs({
        dealType: WebEngageDealType.WithoutCoupon,
        ...deal,
      })
    );

    if (user) {
      const localeInUrl = getLocaleInUrl(defaultLocale, locale);
      const dealHandoffUrl = `${localeInUrl}/handoff/deal/${deal.id}`;

      window.open(dealHandoffUrl, "_blank");
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push({ pathname: `/store/${deal.store.slug}` });
    }
  };

  const cardImagePresent = deal?.store?.cardImageUrl;

  let expiryText;

  if (deal?.endAt) {
    const { value, unit }: GetDateDiffType = getDateDiff(
      new Date(UTCtoLocalTime(deal.endAt)),
      new Date()
    );
    expiryText = ["second", "seconds"].includes(unit)
      ? translate("store_expires_within_title")
      : translate("store_expires_in_title", [
          translate(`num_${unit}`, [value]),
        ]);
  }

  return (
    <div
      className={clsx(styles.withOut, isRtl && styles.rtl)}
      data-testid="deal-card"
    >
      <div className={styles.cardBody}>
        <div className={styles.cardTop}>
          <div
            className={clsx(
              styles.storeImage,
              loading && styles.empty,
              !cardImagePresent && styles.empty
            )}
          >
            {loading || !cardImagePresent ? (
              <img width="120px" height="auto" src={SHOPCASH_LOGO} />
            ) : (
              <Image
                src={deal.store.cardImageUrl}
                layout="fill"
                alt={deal.title}
              ></Image>
            )}

            <div
              className={clsx(styles.storeLogo, loading && styles.empty)}
              data-testid="deal-card-store-logo"
              onClick={() => (loading ? null : handleVisitDealClick(deal))}
            >
              {!loading && (
                <Image
                  src={deal?.store?.logoUrl}
                  layout="fill"
                  alt={deal?.store?.name}
                ></Image>
              )}
            </div>
          </div>
        </div>
        <div className={styles.cardBottom}>
          <div className={clsx(styles.storeName, loading && styles.empty)}>
            <TextDirection text={deal?.store?.name} />
          </div>
          <div className={clsx(styles.dealName, loading && styles.empty)}>
            {deal?.title}
          </div>
          <div className={clsx(styles.dealExpiry, loading && styles.empty)}>
            {expiryText}
          </div>
          <div className={clsx(styles.cashbackText, loading && styles.empty)}>
            <span className={styles.cashbackMain}>
              {deal?.store?.cashbackText}
            </span>
            <span className={styles.cashbackSuffix}>
              {deal?.store?.cashbackTextSuffix}
            </span>
          </div>
          {loading ? (
            <div className={clsx(styles.button, styles.empty)}> </div>
          ) : (
            <Button
              variant={VariantButton.Primary}
              onClick={() => (loading ? null : handleVisitDealClick(deal))}
              fullWidth
            >
              {translate("visit_store")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DealCardWithoutCoupon;
