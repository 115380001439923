import { ButtonWithPreview } from "@components/Button";
import StoreLogo from "@components/StoreLogo";
import DealCouponModal from "@components/_shared/DealCouponModal";
import TextDirection from "@components/_shared/TextDirection";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import { getDateDiff, GetDateDiffType, UTCtoLocalTime } from "@helpers/date";
import {
  getDealCollectionClickEvent,
  getDealCouponModalClickEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import { buildHomePageDealClickAttrs } from "@helpers/webEngage/clickEventBuilders";
import { DealCollectionType } from "@shopcashTypes/dealCollectionType";
import { DealCouponModalCtaType } from "@shopcashTypes/dealCouponModalCtaType";
import { GenzoDealCouponModalAction } from "@shopcashTypes/genzoDealCouponModalAction";
import {
  WebEngageClickEvent,
  WebEngageDealType,
} from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useState } from "react";
import styles from "./DealCardWithCoupon.module.scss";

interface DealCardWithCouponProps {
  deal?: StoreDeal;
  index: number;
  loading?: boolean;
}

const DealCardWithCoupon: React.FC<DealCardWithCouponProps> = ({
  index,
  deal,
  loading,
}) => {
  const translate = useTranslations();
  const router = useRouter();
  const { user } = useAuth();
  const { isRtl } = useSiteConfig();
  const [showModal, setShowModal] = useState(false);
  const { trackEvent } = useGenzoTracking();
  const { trackWebEngageClickEvent } = useWebEngageTracking();

  const handleShowCodeClick = (isLoggedIn: boolean) => {
    const event = getDealCollectionClickEvent(
      deal,
      index,
      "with-coupon",
      "show_code"
    );
    trackEvent(event);
    setShowModal(true);

    if (isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigator?.clipboard?.writeText(deal.couponCode);
    }
  };

  const handleDealCouponModalClose = () => {
    setShowModal(false);
  };

  const handleVisitStoreClick = () => {
    const event = getDealCollectionClickEvent(
      deal,
      index,
      "with-coupon",
      "store_logo"
    );
    trackEvent(event);
    trackWebEngageClickEvent(
      WebEngageClickEvent.homePageDealsClick,
      buildHomePageDealClickAttrs({
        dealType: WebEngageDealType.WithCoupon,
        ...deal,
      })
    );
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({ pathname: `store/${deal.store.slug}` });
  };

  const handleEventTrack = (ctaType: DealCouponModalCtaType) => {
    switch (ctaType) {
      case DealCouponModalCtaType.ShopNow:
        trackEvent(
          getDealCouponModalClickEvent(
            "home",
            "deal_collections",
            deal.id,
            !!deal.store.featuredCashback,
            GenzoDealCouponModalAction.ShopNow,
            "with_coupon"
          )
        );
        break;
      case DealCouponModalCtaType.SignInForCode:
        trackEvent(
          getDealCouponModalClickEvent(
            "home",
            "deal_collections",
            deal.id,
            !!deal.store.featuredCashback,
            GenzoDealCouponModalAction.SignInForCode,
            "with_coupon"
          )
        );
        break;
      case DealCouponModalCtaType.CopyCode:
        trackEvent(
          getDealCouponModalClickEvent(
            "home",
            "deal_collections",
            deal.id,
            !!deal.store.featuredCashback,
            GenzoDealCouponModalAction.CopyCode,
            "with_coupon"
          )
        );
        break;
      case DealCouponModalCtaType.StoreLogo:
        trackEvent(
          getDealCouponModalClickEvent(
            "home",
            "deal_collections",
            deal.id,
            !!deal.store.featuredCashback,
            GenzoDealCouponModalAction.StoreLogo,
            "with_coupon"
          )
        );
        trackWebEngageClickEvent(
          WebEngageClickEvent.homePageDealsClick,
          buildHomePageDealClickAttrs({
            dealType: WebEngageDealType.WithCoupon,
            ...deal,
          })
        );
        break;
    }
  };

  let expiryText;

  if (deal?.endAt) {
    const { value, unit }: GetDateDiffType = getDateDiff(
      new Date(UTCtoLocalTime(deal.endAt)),
      new Date()
    );
    expiryText = ["second", "seconds"].includes(unit)
      ? translate("store_expires_within_title")
      : translate("store_expires_in_title", [
          translate(`num_${unit}`, [value]),
        ]);
  }

  return (
    <div
      className={clsx(styles.card, isRtl && styles.rtl)}
      data-testid="deal-card"
    >
      {showModal && !!deal && (
        <DealCouponModal
          show={showModal}
          deal={deal}
          onCloseClick={handleDealCouponModalClose}
          onEventTrack={handleEventTrack}
          collectionType={DealCollectionType.WithCoupon}
        ></DealCouponModal>
      )}

      {loading ? (
        <div
          className={clsx(styles.cardBody, styles.empty)}
          data-testid="deal-card-loading"
        >
          <div className={styles.storeLogoContainer}>
            <div
              className={clsx(styles.storeLogo)}
              data-testid="deal-card-store-logo"
            ></div>
          </div>
          <div className={styles.storeName}></div>
          <div className={styles.dealName}></div>
          <div className={styles.dealExpiry}></div>
          <div className={styles.cashbackText}></div>
          <div className={styles.button}></div>
        </div>
      ) : (
        <div className={styles.cardBody}>
          <div className={styles.storeLogoContainer}>
            <div
              className={styles.storeLogo}
              data-testid="deal-card-store-logo"
              onClick={handleVisitStoreClick}
            >
              <StoreLogo store={deal.store}></StoreLogo>
            </div>
          </div>
          <div className={styles.storeName}>
            <TextDirection text={deal?.store?.name} />
          </div>
          <div className={styles.dealName}>{deal?.title}</div>
          <div className={styles.dealExpiry}>{expiryText}</div>
          <div className={styles.cashbackText}>
            <span className={styles.cashbackMain}>
              {deal?.store?.cashbackText}
            </span>

            <span className={styles.cashbackSuffix}>
              {deal?.store?.cashbackTextSuffix}
            </span>
          </div>

          <div>
            <ButtonWithPreview
              onClick={() => handleShowCodeClick(!!user)}
              previewText={deal.couponCode}
            >
              {translate("show_code")}
            </ButtonWithPreview>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealCardWithCoupon;
