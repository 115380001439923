import Image from "@components/_shared/NextImage";
import TextDirection from "@components/_shared/TextDirection";
import { useSiteConfig } from "@context/siteConfig";
import clsx from "clsx";
import Link from "next/link";
import React from "react";
import styles from "./StoreCard.module.scss";

interface StoreCardProps {
  store?: StoreSummary;
  onStoreCardClick?: (storeId: string) => void;
}

const StoreCard: React.FC<StoreCardProps> = ({ store, onStoreCardClick }) => {
  const { isRtl } = useSiteConfig();
  const isLoading = !store;

  const handleOnStoreCardClick = (storeId: string): void => {
    onStoreCardClick && onStoreCardClick(storeId);
  };

  return (
    <Link href={`/store/${store?.slug}`}>
      <a
        className={clsx(styles.container, isRtl && styles.rtl)}
        data-testid={isLoading ? "store-card-loading" : "store-card-content"}
        onClick={() => handleOnStoreCardClick(store.id)}
      >
        <div className={clsx(styles.logoContainer, isLoading && styles.empty)}>
          {store?.logoUrl && (
            <Image
              alt={store.name}
              src={store.logoUrl}
              layout="fill"
              objectFit="contain"
            />
          )}
        </div>
        <div className={styles.content}>
          <div className={clsx(styles.name, isLoading && styles.empty)}>
            <TextDirection text={store?.name} />
          </div>
          <div className={clsx(styles.cashbackMain, isLoading && styles.empty)}>
            {store?.cashbackText}
          </div>
          <div
            className={clsx(styles.cashbackSuffix, isLoading && styles.empty)}
          >
            {store?.cashbackTextSuffix}
          </div>
        </div>
      </a>
    </Link>
  );
};

export default StoreCard;
