import AuthenticationModal from "@components/AuthenticationModal";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import HistoryIcon from "@icons/history.svg";
import HowToIcon from "@icons/howTo.svg";
import ReferIcon from "@icons/refer.svg";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import Button from "./../Button";
import styles from "./AccountSummary.module.scss";

const AccountSummary: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { user, isAuthenticating } = useAuth();
  const { isRtl, referrals } = useSiteConfig();
  const translate = useTranslations();
  const router = useRouter();

  const currencyCode = referrals?.referralPayout?.currencyCode || "";
  const payoutAmount = referrals?.referralPayout?.amount || "";
  const referAndEarnValue = `${currencyCode} ${payoutAmount}`.trim();

  const handleOnModalShow = (value: boolean): void => {
    setShowModal(value);
  };

  const handleOnHowToEarnClick = (): void => {
    router.push({ pathname: `/how-to-earn` });
  };

  return (
    <>
      <div className={clsx(isRtl && styles.rtl)}>
        <div className={styles.contentContainer}>
          {/* when still authenticating, display skeleton loader */}
          {!user && isAuthenticating && <LoginForm />}

          {/* when done authenticating and still no user, display login/signup button */}
          {!user && !isAuthenticating && (
            <>
              <LoginForm onLoginClick={() => handleOnModalShow(true)} />
              <Button
                variant={VariantButton.SecondaryWhite}
                large
                fullWidth
                onClick={() => handleOnHowToEarnClick()}
              >
                {translate("how_to_earn_sorting_option")}
              </Button>
            </>
          )}

          {!!user && (
            <>
              <div className={styles.walletSummary}>
                <div className={styles.label}>
                  {translate("welcome_title", [user?.profile?.name || ""])}
                  <span>{translate("available_balance")}:</span>
                </div>
                <div className={styles.balanceAmount}>
                  <sup>{user.ledger.currencyCode}</sup>
                  {(user.ledger.displayBalance || 0).toFixed(2)}
                </div>
              </div>
              <div className={styles.userActionButtons}>
                <Link href="/how-to-earn">
                  <a>
                    <HowToIcon className={styles.icon} />{" "}
                    {translate("how_to_earn")}
                  </a>
                </Link>
                <Link href="/wallet/refer">
                  <a>
                    <ReferIcon className={styles.icon} />
                    {translate("refer_and_earn_up_to", [referAndEarnValue])}
                  </a>
                </Link>
                <Link href="/wallet/cashback-history">
                  <a>
                    <HistoryIcon className={styles.icon} />{" "}
                    {translate("history")}
                  </a>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      {showModal && (
        <AuthenticationModal
          show={showModal}
          onCloseClick={() => handleOnModalShow(false)}
        ></AuthenticationModal>
      )}
    </>
  );
};

interface LoginFormProps {
  onLoginClick?: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLoginClick }) => {
  const translate = useTranslations();

  return (
    <div className={clsx(!onLoginClick && styles.empty)}>
      <div className={styles.description}>{translate("login_incentive")}</div>
      <div className={styles.button}>
        {onLoginClick && (
          <Button
            variant={VariantButton.RoundedSecondary}
            large
            fullWidth
            onClick={onLoginClick}
          >
            {translate("joinnow_signin")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AccountSummary;
