import Carousel from "@components/Carousel";
import Image from "@components/_shared/NextImage";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { getBannerCollectionClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import { buildHomePageBannerClickAttrs } from "@helpers/webEngage/clickEventBuilders";
import { WebEngageClickEvent } from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import Link from "next/link";
import styles from "./BannerCollection.module.scss";

const SKELETON = [...Array(6)];

interface BannerCollectionProps {
  isRtl: boolean;
  banners?: Array<Banner>;
  name?: string;
  type?: string;
}

const BannerCollection: React.FC<BannerCollectionProps> = ({
  isRtl,
  banners,
  name,
  type,
}) => {
  const { trackEvent } = useGenzoTracking();
  const { trackWebEngageClickEvent } = useWebEngageTracking();
  const handleBannerClick = (banner: Banner, index: number) => {
    const event = getBannerCollectionClickEvent(banner.id, index, type);
    trackEvent(event);
    trackWebEngageClickEvent(
      WebEngageClickEvent.homePageBannerClick,
      buildHomePageBannerClickAttrs(banner.store)
    );
  };

  return (
    <section
      className={clsx(
        styles.section,
        isRtl && styles.rtl,
        !banners && styles.loading
      )}
      data-testid={"banner-collection"}
    >
      <div className={styles.title}>{name}</div>

      <Carousel isRtl={isRtl}>
        <div className={styles.bannersContainer}>
          {banners
            ? banners.map((banner, i) => {
                return (
                  <Link key={i} href={`/store/${banner?.store?.slug}`}>
                    <a
                      onClick={() => handleBannerClick(banner, i)}
                      className={styles.banner}
                      data-testid="banner-content"
                    >
                      <div className={styles.imageContainer}>
                        {banner && banner.imageUrl && (
                          <Image
                            src={banner.imageUrl}
                            alt={banner?.name}
                            layout="fill"
                          />
                        )}
                      </div>
                    </a>
                  </Link>
                );
              })
            : SKELETON.map((_, i) => {
                return (
                  <div
                    key={i}
                    className={styles.banner}
                    data-testid="banner-loading"
                  >
                    <div className={styles.imageContainer}></div>
                  </div>
                );
              })}
        </div>
      </Carousel>
    </section>
  );
};

export default BannerCollection;
