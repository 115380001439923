import Carousel from "@components/Carousel";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { getStoreCollectionClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import clsx from "clsx";
import FeaturedStoreCard from "./FeaturedStoreCard";
import styles from "./FeaturedStores.module.scss";

interface FeaturedStoresProps {
  id?: string;
  title?: string;
  stores?: Array<StoreSummary>;
}

const skeletonItems = Array.from(Array(5).keys());

const FeaturedStores: React.FC<FeaturedStoresProps> = ({
  id: collectionId,
  title,
  stores,
}) => {
  const { isRtl } = useSiteConfig();
  const { trackEvent } = useGenzoTracking();

  const handleStoreClick = (store: StoreSummary, index: number) => {
    const event = getStoreCollectionClickEvent(collectionId, store.id, index);
    trackEvent(event);
  };
  return (
    <section
      className={clsx(
        styles.container,
        !stores && styles.loading,
        isRtl && styles.rtl
      )}
    >
      <div className={styles.title}>
        <div>{title}</div>
      </div>
      <Carousel isRtl={isRtl}>
        <div className={styles.storesContainer}>
          {stores
            ? stores.map((s, i) => (
                <div
                  key={s.id}
                  className={styles.store}
                  onClick={() => {
                    handleStoreClick(s, i);
                  }}
                >
                  <FeaturedStoreCard store={s} />
                </div>
              ))
            : skeletonItems.map((i) => (
                <div key={i} className={styles.store}>
                  <FeaturedStoreCard />
                </div>
              ))}
        </div>
      </Carousel>
    </section>
  );
};

export default FeaturedStores;
