import clsx from "clsx";
import { FC } from "react";
import styles from "./Circle.module.scss";

type CircleProps = {
  index?: number;
  handleClick?(index: number): void;
  active?: boolean;
};

const Circle: FC<CircleProps> = ({ index, handleClick, active = false }) => (
  <div
    className={styles.circleContainer}
    onClick={handleClick && (() => handleClick(index))}
  >
    <div className={clsx(styles.circle, active && styles.active)} />
  </div>
);

export default Circle;
