import {
  getDealsAgnosticCollection,
  getDealsWithCouponCollection,
  getDealsWithOutCouponCollection,
} from "@api/collections";
import { useSiteConfig } from "@context/siteConfig";
import useSWR from "swr";

const useDealCollections = () => {
  const { locale, siteCode, appType } = useSiteConfig();
  const { data: withCouponsDealCollection } = useSWR(
    ["withCouponsDealCollection", siteCode, appType, locale],
    (_: string, siteCode: string, appType: string, locale: string) =>
      getDealsWithCouponCollection(siteCode, appType, locale)
  );

  const { data: withoutCouponsDealCollection } = useSWR(
    ["withoutCouponsDealCollection", siteCode, appType, locale],
    (_: string, siteCode: string, appType: string, locale: string) =>
      getDealsWithOutCouponCollection(siteCode, appType, locale)
  );

  const { data: couponAgnosticDealCollection } = useSWR(
    ["couponAgnosticDealCollection", siteCode, appType, locale],
    (_: string, siteCode: string, appType: string, locale: string) =>
      getDealsAgnosticCollection(siteCode, appType, locale)
  );

  return {
    withCouponsDealCollection,
    withoutCouponsDealCollection,
    couponAgnosticDealCollection,
  };
};

export default useDealCollections;
