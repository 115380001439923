import { getBannerCollection } from "@api/collections";
import { useSiteConfig } from "@context/siteConfig";
import useSWR from "swr";

const useBannerCollections = () => {
  const { locale, siteCode, appType } = useSiteConfig();
  const { data: bannerCollection1 } = useSWR(
    ["bannerCollection1", siteCode, appType, locale, null],
    (
      _: string,
      siteCode: string,
      appType: string,
      locale: string,
      collectionNumber: number
    ) => getBannerCollection(siteCode, appType, locale, collectionNumber)
  );

  const { data: bannerCollection2 } = useSWR(
    ["bannerCollection2", siteCode, appType, locale, 2],
    (
      _: string,
      siteCode: string,
      appType: string,
      locale: string,
      collectionNumber: number
    ) => getBannerCollection(siteCode, appType, locale, collectionNumber)
  );

  return {
    bannerCollection1,
    bannerCollection2,
  };
};

export default useBannerCollections;
