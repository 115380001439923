import Image from "@components/_shared/NextImage";
import Link from "next/link";
import { FC } from "react";
import styles from "./HeroImage.module.scss";

type HeroImageProps = {
  link?: string;
  imageUrl?: string;
  handleClick?(): void;
};

const HeroImage: FC<HeroImageProps> = ({ link, imageUrl, handleClick }) => {
  return (
    <>
      {imageUrl && link ? (
        <Link href={link}>
          <a onClick={handleClick} className={styles.container}>
            <Image
              priority={true}
              layout="fill"
              className={styles.image}
              src={imageUrl}
            />
          </a>
        </Link>
      ) : (
        <div className={styles.imageLoader}>
          <img
            alt="ShopCash Logo"
            className={styles.shopcashLogo}
            src="https://assets.wego.com/image/authenticated/s--s8qSzrBA--/v1622111396/web/shopcash/shopcash-logo-grey.svg"
          />
        </div>
      )}
    </>
  );
};

export default HeroImage;
