import clsx from "clsx";
import styles from "./UserEducationVideoButton.module.scss";

const BUTTON_NAMES = {
  ar: "ShopCash-button-AR-200px.gif",
  en: "ShopCash-button-200px.gif",
};

interface UserEducationVideoButtonProps {
  isRtl: boolean;
  locale: string;
  onClick: () => void;
}

const UserEducationVideoButton: React.FC<UserEducationVideoButtonProps> = ({
  isRtl,
  locale,
  onClick,
}) => {
  return (
    <div className={clsx(styles.button, isRtl && styles.rtl)} onClick={onClick}>
      <img
        width="60"
        height="60"
        src={`https://assets.wego.com/image/upload/w_120,h_120/v1627264648/web/shopcash/${BUTTON_NAMES[locale]}`}
      />
    </div>
  );
};

export default UserEducationVideoButton;
