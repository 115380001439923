import { useEffect, useState } from "react";

const useDisableBodyScroll = () => {
  const [isBodyScrollDisabled, setIsBodyScrollDisabled] = useState(false);

  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (isBodyScrollDisabled) {
      bodyClassList.add("bodyScrollDisabled");
    }

    return () => {
      bodyClassList.remove("bodyScrollDisabled");
    };
  }, [isBodyScrollDisabled]);

  return {
    isBodyScrollDisabled,
    setIsBodyScrollDisabled,
  };
};

export default useDisableBodyScroll;
