import { useSiteConfig } from "@context/siteConfig";
import { FC } from "react";
import DesktopHeroCarousel from "./HeroCarousel";
import MobileHeroCarousel from "./MobileHeroCarousel";

type HeroCarouselProps = {
  type: string;
  banners: Array<Banner>;
};

const HeroCarousel: FC<HeroCarouselProps> = ({ type, banners }) => {
  const { isMobile, isRtl } = useSiteConfig();

  return (
    <>
      {isMobile ? (
        <MobileHeroCarousel type={type} banners={banners} isRtl={isRtl} />
      ) : (
        <DesktopHeroCarousel type={type} banners={banners} isRtl={isRtl} />
      )}
    </>
  );
};

export default HeroCarousel;
