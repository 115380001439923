import { useGenzoTracking } from "@context/tracking/genzo";
import { getBannerCollectionClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import useDisableBodyScroll from "@helpers/useDisableBodyScroll";
import useWindowDimensions from "@helpers/useWindowDimensions";
import clsx from "clsx";
import { TouchEvent, useEffect, useState } from "react";
import Circle from "../Circle";
import HeroImage from "../HeroImage";
import styles from "./MobileHeroCarousel.module.scss";

interface MobileHeroCarouselProps {
  type: string;
  banners: Array<Banner>;
  isRtl: boolean;
}

const MobileHeroCarousel: React.FC<MobileHeroCarouselProps> = ({
  type,
  banners,
  isRtl,
}) => {
  return (
    <>
      {banners == undefined && <CarouselSkeleton isRtl={isRtl} />}
      {banners?.length > 0 && (
        <Carousel type={type} banners={banners} isRtl={isRtl} />
      )}
    </>
  );
};

const applyTranslateXLimit = (translateX: number, translateXLimit: number) => {
  if (translateX < 0) return 0;

  if (translateX > translateXLimit) return translateXLimit;

  return translateX;
};

interface CarouselProps {
  type: string;
  banners: Array<Banner>;
  isRtl: boolean;
}

const Carousel: React.FC<CarouselProps> = ({ type, banners, isRtl }) => {
  const { trackEvent } = useGenzoTracking();
  const [selectedBanner, setSelectedBanner] = useState<number>(0);
  const [bannersCount, setBannersCount] = useState<number>(banners?.length);
  const [touchStartX, setTouchStartX] = useState<number>(0);
  const [touchStartTime, setTouchStartTime] = useState<number>(null);
  const [translateX, setTranslateX] = useState<number>(0);
  const [intermediateTranslateX, setIntermediateTranslateX] = useState<number>(
    0
  );
  const [translateXLimit, setTranslateXLimit] = useState(0);
  const [frameWidth, setFrameWidth] = useState(0);
  const [isAnimated, setIsAnimated] = useState(false);

  const { width: windowWidth } = useWindowDimensions();
  const { setIsBodyScrollDisabled } = useDisableBodyScroll();

  useEffect(() => {
    setFrameWidth(windowWidth - 24);
  }, [windowWidth]);

  useEffect(() => {
    const newBannersCount = banners?.length;
    setBannersCount(newBannersCount);
  }, [banners?.length]);

  useEffect(() => {
    if (bannersCount > 1) {
      setTranslateXLimit((bannersCount - 1) * frameWidth);
    } else {
      setTranslateXLimit(0);
    }
  }, [bannersCount, frameWidth]);

  useEffect(() => {
    const newTranslateX = applyTranslateXLimit(
      selectedBanner * frameWidth,
      translateXLimit
    );
    setTranslateX(newTranslateX);
    setIntermediateTranslateX(newTranslateX);
  }, [selectedBanner, frameWidth, translateXLimit]);

  useEffect(() => {
    if (banners?.length > 1) {
      setIsAnimated(true);
    }
  }, [banners?.length]);

  useEffect(() => {
    if (isAnimated) {
      const autoRotateTimer = setTimeout(() => {
        const newSelectedBanner =
          selectedBanner < bannersCount - 1 ? selectedBanner + 1 : 0;

        const newTranslateX = newSelectedBanner * frameWidth;
        setTranslateX(newTranslateX);
        setIntermediateTranslateX(newTranslateX);

        setSelectedBanner(newSelectedBanner);
      }, 5000);

      return () => clearTimeout(autoRotateTimer);
    }
  }, [selectedBanner, bannersCount, frameWidth, isAnimated]);

  const handleOnBannerClick = (banner: Banner, index: number): void => {
    const event = getBannerCollectionClickEvent(banner.id, index, type);
    trackEvent(event);
  };

  const handleOnTouchStart = (e: TouchEvent) => {
    setIsBodyScrollDisabled(true);
    setIsAnimated(false);
    setTouchStartX(e.changedTouches[0].clientX);
    setTouchStartTime(e.timeStamp);
  };

  const handleOnTouchMove = (e: TouchEvent) => {
    let touchDisplacementX = touchStartX - e.changedTouches[0].clientX;

    if (isRtl) {
      touchDisplacementX = -touchDisplacementX;
    }

    const newTranslateX = applyTranslateXLimit(
      intermediateTranslateX + touchDisplacementX,
      translateXLimit
    );

    setTranslateX(newTranslateX);
  };

  const handleOnTouchEnd = (e: TouchEvent) => {
    const touchDuration = e.timeStamp - touchStartTime;

    let touchDisplacementX = touchStartX - e.changedTouches[0].clientX;

    if (isRtl) {
      touchDisplacementX = -touchDisplacementX;
    }

    if (touchDuration < 400 && Math.abs(touchDisplacementX) > 15) {
      let newBannerIndex = selectedBanner + Math.sign(touchDisplacementX);

      if (newBannerIndex > bannersCount - 1 || newBannerIndex < 0) {
        newBannerIndex = selectedBanner;
      }

      const translateXAfterSnapping = newBannerIndex * frameWidth;

      const newTranslateX = applyTranslateXLimit(
        translateXAfterSnapping,
        translateXLimit
      );

      setTranslateX(newTranslateX);
      setIntermediateTranslateX(newTranslateX);
      setSelectedBanner(newBannerIndex);
    } else {
      const newBannerIndex = Math.round(translateX / frameWidth);

      const translateXAfterSnapping = newBannerIndex * frameWidth;
      const newTranslateX = applyTranslateXLimit(
        translateXAfterSnapping,
        translateXLimit
      );
      setTranslateX(newTranslateX);
      setIntermediateTranslateX(newTranslateX);
      setSelectedBanner(newBannerIndex);
    }

    setIsAnimated(true);
    setIsBodyScrollDisabled(false);
  };

  const handleOnCircleClick = (index: number) => {
    const newTranslateX = index * frameWidth;
    setTranslateX(newTranslateX);
    setIntermediateTranslateX(newTranslateX);
    setSelectedBanner(index);
  };

  return (
    <div className={clsx(styles.mainBanners, isRtl && styles.rtl)}>
      <ul
        className={clsx(styles.slides, isAnimated && styles.animated)}
        style={{
          transform: `translateX(${isRtl ? translateX : -translateX}px)`,
        }}
      >
        {banners.map((banner: Banner, index: number) => {
          return (
            <li
              key={index}
              className={styles.slide}
              onTouchStart={handleOnTouchStart}
              onTouchMove={handleOnTouchMove}
              onTouchEnd={handleOnTouchEnd}
            >
              <HeroImage
                link={banner.link || `/store/${banner.store?.slug}`}
                imageUrl={banner.imageUrl}
                handleClick={() => handleOnBannerClick(banner, index)}
              />
            </li>
          );
        })}
      </ul>

      <div className={styles.circles}>
        {Array.from(Array(bannersCount).keys()).map((i) => (
          <Circle
            key={i}
            index={i}
            handleClick={handleOnCircleClick}
            active={i === selectedBanner}
          />
        ))}
      </div>
    </div>
  );
};

const CarouselSkeleton = ({ isRtl }) => (
  <div className={clsx(styles.mainBanners, isRtl && styles.rtl)}>
    <ul className={clsx(styles.slides, styles.loading)}>
      <HeroImage />
    </ul>

    <div className={styles.circles}>
      {Array.from(Array(2).keys()).map((i) => {
        return <Circle key={i} active={i === 0} />;
      })}
    </div>
  </div>
);

export default MobileHeroCarousel;
