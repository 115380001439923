import Image from "@components/_shared/NextImage";
import TextDirection from "@components/_shared/TextDirection";
import { useSiteConfig } from "@context/siteConfig";
import clsx from "clsx";
import Link from "next/link";
import styles from "./FeaturedStoreCard.module.scss";

const SHOPCASH_LOGO =
  "https://assets.wego.com/image/authenticated/s--s8qSzrBA--/v1622111396/web/shopcash/shopcash-logo-grey.svg";

interface FeaturedStoreCardProps {
  store?: StoreSummary;
}

const FeaturedStoreCard: React.FC<FeaturedStoreCardProps> = ({ store }) => {
  const { isRtl } = useSiteConfig();
  return (
    <Link href={`/store/${store?.slug}`}>
      <a
        className={clsx(
          styles.container,
          isRtl && styles.rtl,
          !store && styles.empty
        )}
      >
        <div
          className={clsx(
            styles.cardImageContainer,
            !store?.cardImageUrl && styles.empty
          )}
        >
          {store?.cardImageUrl ? (
            <Image
              alt={`${store.name} Card Image`}
              layout="fill"
              src={store?.cardImageUrl}
            />
          ) : (
            <>
              <img
                className={styles.shopcashLogo}
                width="120px"
                height="auto"
                src={SHOPCASH_LOGO}
              />
            </>
          )}
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.logoContainer}>
            <div>
              {store?.logoUrl && (
                <Image
                  alt={`${store.name} Logo`}
                  layout="fill"
                  src={store.logoUrl}
                />
              )}
            </div>
          </div>
          <div className={styles.texts}>
            <div className={styles.name}>
              <TextDirection text={store?.name} />
            </div>
            <div className={styles.cashbackText}>{store?.cashbackText}</div>
            <div className={styles?.cashbackTextSuffix}>
              {store?.cashbackTextSuffix}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default FeaturedStoreCard;
