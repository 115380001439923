import Button from "@components/Button";
import Modal from "@components/_shared/Modal";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import { getUserEducationVideoButtonClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import { useEffect } from "react";
import styles from "./UserEducationVideoModal.module.scss";

interface UserEducationVideoModalProps {
  onCloseClick: () => void;
}

const UserEducationVideoModal: React.FC<UserEducationVideoModalProps> = ({
  onCloseClick,
}) => {
  const { userEducationVideoUrl, isMobile } = useSiteConfig();
  const translate = useTranslations();
  const { trackEvent } = useGenzoTracking();

  useEffect(() => {
    if (userEducationVideoUrl) {
      const event = getUserEducationVideoButtonClickEvent(
        userEducationVideoUrl
      );
      trackEvent(event);
    }
  }, [userEducationVideoUrl]);

  return (
    <Modal className={styles.modal} show={true} onOverlayClick={onCloseClick}>
      <div className={clsx(styles.educationVideo, isMobile && styles.isMobile)}>
        <div className={styles.title}>{translate("title_how_it_works")}</div>
        <div className={styles.iframeWrapper}>
          <iframe
            src={`${userEducationVideoUrl}?rel=0&autoplay=1&playsinline=1`}
            frameBorder="0"
            height="100%"
            width="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            large
            fullWidth
            variant={VariantButton.Primary}
            onClick={onCloseClick}
          >
            {translate("browse_stores")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UserEducationVideoModal;
